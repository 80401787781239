/** @format */

import { usePasswordForgot } from "@roadflex/react-hooks-www";
import { toast } from "react-toastify";
import ForgotPasswordUI from "./forgot-password.ui";

export function ForgotPassword() {
  const {
    values,
    handleChange,
    handleSubmit,
    errors,
    isSubmitting,
    resetForm,
    ...rest
  } = usePasswordForgot({
    initialValues: {
      email: "",
    },
    onSubmit(res) {
      if (res.code === "200") {
        toast.success(res?.message);
      } else {
        toast.error(res.message);
      }
      resetForm();
    },
  });

  return (
    <ForgotPasswordUI
      {...{
        values,
        errors,
        handleChange,
        handleSubmit,
        isSubmitting,
        resetForm,
        ...rest,
      }}
    />
  );
}
