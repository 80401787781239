/** @format */

import { ForgotPasswordValues } from "@roadflex/types";
import { FormikProps } from "formik";
import { Button, ButtonType, ButtonVariant } from "../../buttons";
import { TextInput } from "../../inputs";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ForgotPasswordUIProps extends FormikProps<ForgotPasswordValues> {}

function ForgotPasswordUI({
  values,
  handleChange,
  handleSubmit,
  touched,
  errors,
  handleBlur,
  isSubmitting,
}: ForgotPasswordUIProps) {
  return (
    <div className="flex items-center justify-center w-full min-h-full py-12 md:px-4 sm:px-6 lg:px-8">
      <div className="container flex flex-col items-center justify-center h-full my-8 ">
        <div>
          <div
            className="px-4 py-12 sm:px-20 sm:py-16 border-2 rounded-xl  min-h-[300px] md:min-h-[600px] w-full xl:w-[1250px] lg:w-[1000px] md:w-[750px]"
            style={{
              background: "linear-gradient(130deg,#29120A 0, #823920 100%)",
            }}
          >
            <div className="flex flex-col">
              <div className="text-xl font-semibold text-center text-white">
                Create New password
              </div>
              <div className="my-8 text-center text-white max-w-[350px] self-center">
                Enter your email and we will send you a link to create your new
                password.
              </div>
              <form className="w-full" onSubmit={handleSubmit}>
                <div className="relative w-full">
                  <TextInput
                    label="Work Email"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.email && Boolean(errors.email)}
                    errorMessage={errors.email}
                    touched={touched.email}
                    variant="responsive"
                    required
                    color={"text-white"}
                  ></TextInput>
                </div>

                <div className="mt-12">
                  <Button
                    type={ButtonType.Submit}
                    variant={ButtonVariant.Orange}
                    className="w-full"
                    disabled={isSubmitting}
                  >
                    Send Link
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPasswordUI;
