import { ExclamationCircleIcon } from "@heroicons/react/outline";
import { PhoneVerificationValues } from "@roadflex/types";
import { phoneFormatter } from "@roadflex/web-lib";
import classNames from "classnames";
import { FormikProps } from "formik";
import { Tooltip } from "primereact/tooltip";
import { Button, ButtonType, ButtonVariant } from "../buttons";
import { OTPModal } from "../modals/otp-modal/otp-modal";

type PhoneVerificationUIProps = FormikProps<PhoneVerificationValues> & {
  showOtpModal: boolean;
  setShowOtpModal: (showOtpModal: boolean) => void;
  onOtpSubmit: (value: PhoneVerificationValues) => void;
  otpSubmitting: boolean;
  sendOTP: () => void;
  sendingOTP: boolean;
};
export const PhoneVerificationUI = (props: PhoneVerificationUIProps) => {
  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    errors,
    touched,
    setFieldValue,
    submitForm,
    showOtpModal,
    setShowOtpModal,
    onOtpSubmit,
    otpSubmitting,
    sendOTP,
    sendingOTP,
    ...rest
  } = props;
  return (
    <div className="flex-1 w-full h-full bg-cover bg-onboard-texture">
      <div className="container flex flex-col items-center justify-center h-full my-8 ">
        <div>
          <div className="mb-6 text-3xl">Verify Your Number</div>
          <div
            className="px-4 py-8 sm:px-8 sm:py-8 border-2 rounded-xl  w-full md:w-[350px]"
            style={{
              background: "linear-gradient(130deg,#29120A 0, #823920 100%)",
            }}
          >
            <div className="">
              <div className="mb-6 text-xl font-bold text-center text-white">
                Phone Number
              </div>
              <form className="w-full">
                <div className="relative w-full mt-4">
                  <input
                    type="tel"
                    name="phoneNumber"
                    value={values.phoneNumber}
                    onChange={(event) =>
                      setFieldValue(
                        "phoneNumber",
                        phoneFormatter(event?.target?.value),
                      )
                    }
                    onBlur={handleBlur}
                    className={classNames(
                      touched.phoneNumber && errors?.phoneNumber
                        ? "border-red-500"
                        : "",
                      "block w-full pl-3 pr-8 py-2 border rounded focus:outline-none",
                    )}
                  />
                  {touched.phoneNumber && errors?.phoneNumber && (
                    <>
                      <Tooltip
                        target=".phoneNumber-error"
                        position="top"
                        className="red-tooltip"
                        content={errors.phoneNumber}
                      ></Tooltip>

                      <div className="absolute h-[42px] bottom-0 right-0 pr-3 flex items-center">
                        <ExclamationCircleIcon
                          className="w-5 h-5 text-red-500 phoneNumber-error"
                          aria-hidden="true"
                        />
                      </div>
                    </>
                  )}
                </div>

                <div className="mt-4">
                  <Button
                    type={ButtonType.Button}
                    variant={ButtonVariant.Orange}
                    className="w-full"
                    onClick={sendOTP}
                    loading={sendingOTP}
                    disabled={typeof errors?.phoneNumber === "string"}
                  >
                    Verify
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {showOtpModal && (
        <OTPModal
          {...{
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            errors,
            touched,
            setFieldValue,
            submitForm,
            ...rest,
          }}
          show={showOtpModal}
          setShow={setShowOtpModal}
          onOtpSubmit={onOtpSubmit}
          otpSubmitting={otpSubmitting}
          resendCode={sendOTP}
          message={"Enter the 6-digit verification code sent via SMS"}
        />
      )}
    </div>
  );
};
