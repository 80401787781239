/** @format */
import { useMutation } from "@apollo/client";
import { wwwEnterpriseApplicationValidationSchema } from "@roadflex/constants";
import { ENTERPRISE_APPLICATION } from "@roadflex/graphql";
import { EnterpriseApplicationValues } from "@roadflex/types";
import { adminUploadFile } from "@roadflex/web-api";
import { useFormik } from "formik";

type useEnterpriseSignupProps = {
  onSubmit: (val: any, err: any) => void;
  initialValues: EnterpriseApplicationValues;
};

export const useEnterpriseSignup = ({
  onSubmit,
  initialValues,
}: useEnterpriseSignupProps) => {
  const [EnterpriseApplicationFn] = useMutation(ENTERPRISE_APPLICATION);
  const {
    handleChange,
    handleSubmit,
    handleBlur,
    values,
    isSubmitting,
    ...rest
  } = useFormik({
    initialValues,
    validationSchema: wwwEnterpriseApplicationValidationSchema,
    onSubmit: async (value) => {
      try {
        if (value.statements.length === 3) {
          const statement1 = await adminUploadFile(
            value.statements[0],
            "statement_1",
          );
          const statement2 = await adminUploadFile(
            value.statements[1],
            "statement_2",
          );
          const statement3 = await adminUploadFile(
            value.statements[2],
            "statement_3",
          );
          const list = value.differentDeliveryAddress
            ? [
                {
                  ...value.businessAddress,
                  type: ["PERMANENT"],
                },
                {
                  ...value.deliveryAddress,
                  type: ["DELIVERY"],
                },
              ]
            : [
                {
                  ...value.businessAddress,
                  type: ["PERMANENT", "DELIVERY"],
                },
                {},
              ];

          const formattedFuelVal = value.fuel.replace("$", "");
          const fuelCentsVal = parseFloat(formattedFuelVal) * 100;

          const payloadData = {
            ...value,
            phoneNumber:
              value.phoneNumber?.trim()?.match(/\d+/g)?.join("") || "",
            ssn: value.ssn ? value.ssn.replace(/\D/g, "") : "",
            fuel: fuelCentsVal,
            businessAddress: list[0],
            deliveryAddress: list[1],
            fleetNum: parseInt(value.fleetNum, 10),
            statements: [statement1, statement2, statement3],
          };
          const res = await EnterpriseApplicationFn({
            variables: { data: payloadData },
          });
          onSubmit(res, null);
        }
      } catch (error) {
        console.error(error);
        onSubmit(null, error);
      }
    },
  });

  return {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    ...rest,
  };
};
