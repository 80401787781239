/** @format */

import { ExclamationCircleIcon } from "@heroicons/react/outline";
import classNames from "classnames";
import { Tooltip } from "primereact/tooltip";
import React, { forwardRef, useEffect, useState } from "react";

interface TextAreaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string;
  error?: boolean;
  errorMessage?: string;
  touched?: boolean;
  variant?: "small" | "medium" | "large" | "responsive";
  className?: string;
  required?: boolean;
  color?: string;
}

export const TextAreaInput = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (
    {
      label,
      name,
      value,
      onChange,
      onBlur,
      placeholder = "",
      error = false,
      errorMessage = "",
      touched = false,
      variant = "medium",
      className,
      required = false,
      color = "text-black",
      ...props
    },
    ref,
  ) => {
    const [variantValue, setVariantValue] = useState(variant);

    useEffect(() => {
      const updateVariant = () => {
        if (window.innerWidth >= 768) {
          setVariantValue("large");
        } else {
          setVariantValue("medium");
        }
      };
      if (variant === "responsive") {
        // Initial check
        updateVariant();

        // Add event listener
        window.addEventListener("resize", updateVariant);

        // Cleanup event listener on component unmount
      }
      return () => window.removeEventListener("resize", updateVariant);
    }, [variant]);

    const textAreaClass = classNames(
      touched && error
        ? "border-red-500 focus:border-red-500 border-2"
        : "border-brown-500 focus:border-brown-500",
      "border placeholder-brown-700 placeholder-opacity-50 w-full rounded-md sm:max-w-full focus:[box-shadow:none] focus:outline-none bg-white ",
      {
        "p-1 py-1 pl-2 text-[12px] pr-2 leading-[18.4px]":
          variantValue === "small",
        "p-2 py-2 pl-2 text-[14px] leading-[18.4px] pr-2":
          variantValue === "medium",
        "p-3 py-3 pl-3 pr-3 sm:text-base": variantValue === "large",
        "!pr-4": variantValue === "small" && error,
        "!pr-8": variantValue === "medium" && error,
        "!pr-10": variantValue === "large" && error,
      },
      className,
    );

    const labelClass = classNames(
      "block",
      {
        "text-sm mb-1": variantValue === "small",
        "text-base mb-1": variantValue === "medium",
        "text-base mb-2": variantValue === "large",
      },
      color,
    );

    const errorIconClass = classNames(
      "absolute bottom-0 right-0  flex items-center cursor-pointer",
      {
        "w-3.5 h-3.5 mr-2.5 mb-[6.5px]": variantValue === "small",
        "w-4 h-4 mb-[10.5px] mr-3": variantValue === "medium",
        "w-5 h-5 mb-[14.5px] mr-4": variantValue === "large",
      },
    );

    return (
      <div className="relative">
        {label && (
          <div className={labelClass}>
            {label}
            {required && "*"}
          </div>
        )}
        <textarea
          name={name}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          placeholder={placeholder}
          className={textAreaClass}
          ref={ref}
          {...props}
        />
        {touched && error && (
          <div className={errorIconClass}>
            <Tooltip
              target={`.${name}-error`}
              position="top"
              className={classNames("red-tooltip", {
                "text-sm": variantValue === "small",
                "text-base":
                  variantValue === "medium" || variantValue === "large",
              })}
              content={errorMessage}
            ></Tooltip>
            <ExclamationCircleIcon
              className={`text-red-500 ${name}-error`}
              aria-hidden="true"
            />
          </div>
        )}
      </div>
    );
  },
);

export default TextAreaInput;
