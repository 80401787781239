/** @format */

import { ExclamationCircleIcon } from "@heroicons/react/outline";
import classNames from "classnames";
import { Dropdown, DropdownProps } from "primereact/dropdown";
import { Tooltip } from "primereact/tooltip";
import React, { forwardRef, useEffect, useState } from "react";

export const Select = forwardRef<
  Dropdown,
  DropdownProps & {
    variant?: "small" | "medium" | "large" | "responsive";
    error?: boolean;
    errorMessage?: string;
    label?: string;
    touched?: boolean;
    labelClass?: string;
    color?: string;
    subLabel?: string;
  }
>(
  (
    {
      value,
      options,
      onChange,
      placeholder = "Select an option",
      className,
      panelClassName,
      optionLabel = "label",
      optionValue = "value",
      itemTemplate,
      disabled = false,
      style,
      variant = "medium",
      error = false,
      errorMessage,
      required,
      label,
      subLabel,
      touched,
      name,
      labelClass,
      color = "text-black",
      ...props
    },
    ref,
  ) => {
    const [variantValue, setVariantValue] = useState(variant);

    useEffect(() => {
      const updateVariant = () => {
        if (window.innerWidth >= 768) {
          setVariantValue("large");
        } else {
          setVariantValue("medium");
        }
      };
      if (variant === "responsive") {
        // Initial check
        updateVariant();

        // Add event listener
        window.addEventListener("resize", updateVariant);

        // Cleanup event listener on component unmount
      }
      return () => window.removeEventListener("resize", updateVariant);
    }, []);

    const selectClass = classNames("custom-select w-full relative", className, {
      "border-red-500": error,
      small: variantValue === "small",
      medium: variantValue === "medium",
      large: variantValue === "large",
    });

    const panelClass = classNames("custom-select-panel", panelClassName, {
      small: variantValue === "small",
      medium: variantValue === "medium",
      large: variantValue === "large",
    });
    const labelClassName = classNames(
      "block",
      {
        "text-sm mb-1": variantValue === "small",
        "text-base mb-1": variantValue === "medium",
        "text-base mb-2": variantValue === "large",
      },
      labelClass,
      color,
    );

    const subClassName = classNames("block italic", {
      "text-xs": variantValue === "small",
      "text-sm": variantValue === "medium" || variantValue === "large",
    });

    const errorIconClass = classNames(
      "absolute bottom-0 right-0 flex items-center cursor-pointer",
      {
        "w-3.5 h-3.5 mr-2.5 mb-[6.5px]": variantValue === "small",
        "w-4 h-4 mb-[10.5px] mr-8": variantValue === "medium",
        "w-5 h-5 mb-[14.5px] mr-10": variantValue === "large",
      },
    );
    return (
      <div className="relative">
        {label && (
          <div className={labelClassName}>
            {label}
            {required && "*"}
            <div>
              <span className={classNames(color, subClassName)}>
                {subLabel}
              </span>
            </div>
          </div>
        )}
        <Dropdown
          value={value}
          options={options}
          onChange={onChange}
          placeholder={placeholder}
          className={selectClass}
          panelClassName={panelClass}
          optionLabel={optionLabel}
          optionValue={optionValue}
          itemTemplate={itemTemplate}
          disabled={disabled}
          style={style}
          {...props}
        />
        {touched && error && (
          <div className={errorIconClass}>
            <Tooltip
              target={`.${name?.replace(".", "-")}-error`}
              position="top"
              className={classNames("red-tooltip", {
                "text-sm": variantValue === "small",
                "text-base":
                  variantValue === "medium" || variantValue === "large",
              })}
              content={errorMessage}
            ></Tooltip>
            <ExclamationCircleIcon
              className={`text-red-500 ${name?.replace(".", "-")}-error`}
              aria-hidden="true"
            />
          </div>
        )}
      </div>
    );
  },
);

export default Select;
