/** @format */

import { ApolloProvider } from "@apollo/client";
import { WwwLayout } from "@roadflex/react-components-www";
import { default as NextProgress } from "next-progress";
import { AppProps } from "next/app";
import dynamic from "next/dynamic";
import Script from "next/script";
import "primeicons/primeicons.css"; //icons
import "primereact/resources/primereact.min.css"; //core css
import "primereact/resources/themes/tailwind-light/theme.css"; //theme
import { useEffect } from "react";
import TagManager from "react-gtm-module";
import "react-toastify/dist/ReactToastify.css";
import { useApollo } from "../apollo/client";
import "./styles.css";

const ToastContainer = dynamic(() =>
  import("react-toastify").then((module) => module.ToastContainer),
);

function CustomApp({ Component, pageProps }: AppProps) {
  const tagManagerArgs = {
    gtmId: "GTM-PPRKLSDQ",
  };
  useEffect(() => {
    TagManager.initialize(tagManagerArgs);
  }, []);
  const apolloClient = useApollo(pageProps.initialApolloState);

  return (
    <ApolloProvider client={apolloClient}>
      <ToastContainer />
      <NextProgress
        color="#ed4e34"
        height={5}
        delay={0}
        options={{ minimum: 0.3, trickleSpeed: 100 }}
      />
      <Script
        type="text/javascript"
        id="ze-snippet"
        src="https://static.zdassets.com/ekr/snippet.js?key=3ed13a39-b844-4709-b70b-612173379ff1"
      ></Script>
      <WwwLayout>
        <Component {...pageProps} />
      </WwwLayout>
    </ApolloProvider>
  );
}

export default CustomApp;
