/** @format */

import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { ENUM_MAIL_TYPE } from "@prisma/client";
import { useLandingPageFormSubmission } from "@roadflex/react-hooks-www";
import { handleGoogleReCaptchaSubmit, phoneFormatter } from "@roadflex/web-lib";
import getConfig from "next/config";
import { useRouter } from "next/router";
import { Fragment, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { toast } from "react-toastify";
import { Button, ButtonType, ButtonVariant } from "../../buttons";
import { TextInput } from "../../inputs";

const { publicRuntimeConfig } = getConfig();
const { RECAPTCHA_SITE_KEY } = publicRuntimeConfig;

type Props = {
  fullReport: boolean;
  setFullReport: (value: boolean) => void;
  numberTrucks: number;
  gallonsPerFillUp: number;
  fillUpsPerWeek: number;
  currentFuelCard: string;
  currentTelematics: string;
};

export function SavingsModal({
  fullReport,
  setFullReport,
  numberTrucks,
  gallonsPerFillUp,
  fillUpsPerWeek,
  currentFuelCard,
  currentTelematics,
}: Props) {
  const router = useRouter();
  const {
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    errors,
    isSubmitting,
    setFieldValue,
    touched,
  } = useLandingPageFormSubmission({
    initialValues: {
      landingPageMailType: ENUM_MAIL_TYPE.SAVINGS,
      firstName: "",
      lastName: "",
      companyName: "",
      email: "",
      phoneNumber: "",
      numberTrucks,
      gallonsPerFillUp,
      fillUpsPerWeek,
      currentFuelCard,
      currentTelematics,
    },
    onSubmit(res, error) {
      if (error) {
        toast.error(error.message);
        return;
      }
      if (res?.code === "200") {
        toast.success(res?.message);
        setFullReport(false);
        router.push({
          pathname: "/",
        });
      } else {
        setFullReport(false);
        toast.error("An error occurred when you submitted the request.");
      }
    },
  });
  const captchaRef = useRef<ReCAPTCHA>(null);
  return (
    <Transition.Root show={fullReport} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-20 overflow-y-auto"
        onClose={setFullReport}
      >
        <div className="flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block w-full px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-md shadow-xl sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <button
                type="button"
                className="bg-white absolute right-[10px] rounded-md text-primary focus:outline-none"
                onClick={() => setFullReport(false)}
              >
                <span className="sr-only">Close</span>
                <XIcon className="w-6 h-6" aria-hidden="true" />
              </button>
              <form
                className="w-full border-0 max-w-[800px] "
                onSubmit={(event) => {
                  handleGoogleReCaptchaSubmit(event, captchaRef, handleSubmit);
                }}
                // onSubmit={handleSubmit}
              >
                <div className="grid w-full gap-2 text-black md:gap-6">
                  <div className="relative col-span-12 mt-8 md:col-span-12">
                    <TextInput
                      name="firstName"
                      value={values.firstName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.firstName && Boolean(errors.firstName)}
                      errorMessage={errors.firstName}
                      touched={touched.firstName}
                      variant="medium"
                      placeholder="First (and Middle) Name"
                      required
                    ></TextInput>
                  </div>
                  <div className="relative col-span-12 md:col-span-12">
                    <TextInput
                      name="lastName"
                      value={values.lastName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.lastName && Boolean(errors.lastName)}
                      errorMessage={errors.lastName}
                      touched={touched.lastName}
                      variant="medium"
                      placeholder="Last Name"
                      required
                    ></TextInput>
                  </div>
                  <div className="relative col-span-12 md:col-span-12">
                    <TextInput
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.email && Boolean(errors.email)}
                      errorMessage={errors.email}
                      touched={touched.email}
                      variant="medium"
                      placeholder="Email"
                      required
                    ></TextInput>
                  </div>
                  <div className="relative col-span-12 md:col-span-12">
                    <TextInput
                      name="phoneNumber"
                      type="tel"
                      value={values.phoneNumber}
                      onChange={(event) =>
                        setFieldValue(
                          "phoneNumber",
                          phoneFormatter(event?.target?.value),
                        )
                      }
                      onBlur={handleBlur}
                      error={touched.phoneNumber && Boolean(errors.phoneNumber)}
                      errorMessage={errors.phoneNumber}
                      touched={touched.phoneNumber}
                      variant="medium"
                      placeholder="Email"
                      required
                    ></TextInput>
                  </div>
                  <div className="relative col-span-12 md:col-span-12">
                    <TextInput
                      name="companyName"
                      value={values.companyName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.companyName && Boolean(errors.companyName)}
                      errorMessage={errors.companyName}
                      touched={touched.companyName}
                      variant="medium"
                      placeholder="Company Name"
                      required
                    ></TextInput>
                  </div>
                </div>
                <ReCAPTCHA
                  className="invisible recaptcha"
                  sitekey={RECAPTCHA_SITE_KEY}
                  ref={captchaRef}
                  size="invisible"
                />
                <div className="flex items-center justify-center w-full mt-4 md:my-10">
                  <Button
                    type={ButtonType.Submit}
                    variant={ButtonVariant.Orange}
                    className="sm:!px-20 font-medium"
                    disabled={isSubmitting}
                    loading={isSubmitting}
                  >
                    Get Full Report
                  </Button>
                </div>
              </form>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default SavingsModal;
