/** @format */

import { usePasswordReset } from "@roadflex/react-hooks-www";
import { deleteCookie } from "@roadflex/web-lib";
import { toast } from "react-toastify";
import ResetPasswordUI from "./reset-password.ui";

type Props = {
  token: string;
};

export function ResetPassword({ token }: Props) {
  const {
    values,
    handleChange,
    handleSubmit,
    isSubmitting,
    errors,
    touched,
    handleBlur,
    ...rest
  } = usePasswordReset({
    initialValues: {
      password: "",
      retypePassword: "",
      token,
    },
    onSubmit(res, error) {
      if (error) {
        toast.error(error.message);
        return;
      }
      toast.success("Password reset success");
      deleteCookie("token");
      window.location.href = "/signin";
    },
  });

  return (
    <ResetPasswordUI
      {...{
        values,
        handleChange,
        handleSubmit,
        isSubmitting,
        errors,
        touched,
        handleBlur,
        ...rest,
      }}
    />
  );
}
