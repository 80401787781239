/** @format */

import { ResetPasswordValues } from "@roadflex/types";
import { FormikProps } from "formik";
import { Button, ButtonSize, ButtonType, ButtonVariant } from "../../buttons";
import { TextInput } from "../../inputs";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ResetPasswordUIProps extends FormikProps<ResetPasswordValues> {}

function ResetPasswordUI({
  values,
  handleChange,
  handleSubmit,
  isSubmitting,
  errors,
  touched,
  handleBlur,
}: ResetPasswordUIProps) {
  return (
    <div className="flex items-center justify-center w-full min-h-full py-12 md:px-4 sm:px-6 lg:px-8">
      <div className="container flex flex-col items-center justify-center h-full my-8">
        <div>
          <div
            className="px-4 py-12 sm:px-20 sm:py-16 border-2 rounded-xl  min-h-[300px] md:min-h-[600px] w-full xl:w-[1250px] lg:w-[1000px] md:w-[750px]"
            style={{
              background: "linear-gradient(130deg,#29120A 0, #823920 100%)",
            }}
          >
            <div className="">
              <div className="mb-6 text-xl font-bold text-center text-white">
                Create New Password
              </div>
              <form className="flex flex-col w-full" onSubmit={handleSubmit}>
                <div className="relative w-full">
                  <TextInput
                    label="Password"
                    type="password"
                    required
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.password && Boolean(errors.password)}
                    errorMessage={errors.password}
                    touched={touched.password}
                    variant="responsive"
                    color={"text-white"}
                  ></TextInput>
                </div>
                <div className="relative w-full mt-4">
                  <TextInput
                    label="Confirm Password"
                    type="password"
                    required
                    name="retypePassword"
                    value={values.retypePassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.retypePassword && Boolean(errors.retypePassword)
                    }
                    errorMessage={errors.retypePassword}
                    touched={touched.retypePassword}
                    variant="responsive"
                    color={"text-white"}
                  ></TextInput>
                </div>
                <div className="mt-6 text-right">
                  <Button
                    type={ButtonType.Submit}
                    variant={ButtonVariant.Orange}
                    size={ButtonSize.Normal}
                    className="w-full"
                    disabled={isSubmitting}
                  >
                    Create
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResetPasswordUI;
