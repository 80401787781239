/** @format */

import { SignInValues } from "@roadflex/types";
import { FormikProps } from "formik";
import getConfig from "next/config";
import Link from "next/link";
import { useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Button, ButtonType, ButtonVariant } from "../buttons";
import { TextInput } from "../inputs";
import { OTPModal } from "../modals/index-www";

const { publicRuntimeConfig } = getConfig();
const { NEXT_PUBLIC_WWW_URL, RECAPTCHA_SITE_KEY } = publicRuntimeConfig;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface SignInUIProps extends FormikProps<SignInValues> {
  showDeviceVerificationModal: boolean;
  setShowDeviceVerificationModal: (param: boolean) => void;
  formikStateAndHelpers: FormikProps<{ otp: string }>;
  onOtpSubmit: (value: { otp: string }) => void;
  otpSubmitting: boolean;
  onResendCode: () => void;
}

export default function SignInUI({
  values,
  handleChange,
  handleSubmit,
  handleBlur,
  touched,
  errors,
  showDeviceVerificationModal,
  setShowDeviceVerificationModal,
  formikStateAndHelpers,
  onOtpSubmit,
  otpSubmitting,
  onResendCode,
}: SignInUIProps) {
  const captchaRef = useRef<ReCAPTCHA>(null);

  return (
    <div className="flex items-center justify-center w-full min-h-full py-12 md:px-4 sm:px-6 lg:px-8">
      <div className="container flex flex-col items-center justify-center h-full my-8">
        <div>
          <div
            className="px-10 py-12 sm:px-20 sm:py-16 rounded-xl  min-h-[300px] md:min-h-[600px] w-full xl:w-[1250px] lg:w-[1000px] md:w-[750px]"
            style={{
              background: "linear-gradient(130deg,#29120A 0, #823920 100%)",
            }}
          >
            <div className="">
              <div className="mb-6 text-xl font-semibold text-center text-white">
                Log in
              </div>
              <form
                className="w-full"
                // onSubmit={(event) => {
                //   handleGoogleReCaptchaSubmit(event, captchaRef, handleSubmit);
                // }}
                onSubmit={handleSubmit}
              >
                <div className="relative w-full">
                  <TextInput
                    label="Email"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.email && Boolean(errors.email)}
                    errorMessage={errors.email}
                    touched={touched.email}
                    variant="responsive"
                    required
                    color={"text-white"}
                  ></TextInput>
                </div>
                <div className="relative w-full mt-4">
                  <TextInput
                    label="Password"
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.password && Boolean(errors.password)}
                    errorMessage={errors.password}
                    touched={touched.password}
                    variant="responsive"
                    required
                    color={"text-white"}
                    type={"password"}
                  ></TextInput>
                </div>
                <div className="mt-4 text-sm md:text-base">
                  <Link href="/forgot-password">
                    <a className="font-medium text-white underline underline-offset-1">
                      Forgot your password?
                    </a>
                  </Link>
                </div>
                <ReCAPTCHA
                  className="invisible recaptcha"
                  sitekey={RECAPTCHA_SITE_KEY}
                  ref={captchaRef}
                  size="invisible"
                />
                <div className="mt-8">
                  <Button
                    type={ButtonType.Submit}
                    variant={ButtonVariant.Orange}
                    className="w-full"
                  >
                    Sign In
                  </Button>
                </div>
              </form>
              <div className="mt-4 text-sm text-center text-white md:text-base">
                Do not have an account?&nbsp;
                <Link href={`${NEXT_PUBLIC_WWW_URL}/signup`}>
                  <a
                    href={`${NEXT_PUBLIC_WWW_URL}/signup`}
                    className="font-medium underline text-white-600 hover:text-white-500 underline-offset-1"
                  >
                    Sign Up
                  </a>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showDeviceVerificationModal && (
        <OTPModal
          {...{
            ...formikStateAndHelpers,
          }}
          show={showDeviceVerificationModal}
          setShow={setShowDeviceVerificationModal}
          onOtpSubmit={onOtpSubmit}
          otpSubmitting={otpSubmitting}
          resendCode={onResendCode}
          heading={`Verify your Device`}
          hideCrossButton={true}
          message={"Enter the 6-digit verification code sent via SMS"}
        />
      )}
    </div>
  );
}
